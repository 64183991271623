.sentiment-page {
    text-align: center;
}

.sentiment-page .search-bar {
    margin: 5vh auto;
    padding-top: 10px;
    width: 95vw;
    max-width: 650px;
    padding: 0 10px;
    vertical-align: middle;
}

.sentiment-page .search-bar textarea {
    padding: 5px 10px;
    width: 100%;
    height: 100px;
}

#search-box-textarea::-webkit-input-placeholder {
    padding-top: 10px;
    text-align: center;
 }
 
#search-box-textarea::-moz-input-placeholder {
    padding-top: 10px;
    text-align: center;
}

#search-box-textarea:-moz-input-placeholder {
    padding-top: 10px;
    text-align: center;
}

#search-box-textarea:-ms-input-placeholder {
    padding-top: 10px;
    text-align: center;
}

.sentiment-page button {
    height: 45px;
    vertical-align: middle;
}

.sentiment-page .word {
    display: inline-block;
    font-size: 22px;
    margin: 3px;
    cursor: pointer;
    padding: 0 10px;
}

.offensive-word {
    border: dashed 4px red;
    background: rgb(255, 245, 245);
}

.positive-word {
    border: dashed 4px green;
    background: rgb(255 255 240);
}

.emotions .emotion {
    display: inline-block;
    margin: 0 5px;
    font-size: 72px;
    opacity: 0.4;
}

.emotions .highlight-emotion {
    opacity: 1;
    font-size: 76px;
}