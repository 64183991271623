.partner-logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    min-height: 150px;
}


.partners-caption {
    text-align: center;
}

.media-caption {
    text-align: center;
}
  