.speech-access-page {
  text-align: center;
}

.speech-access-page .search-bar {
  margin: 5vh auto;
  padding-top: 10px;
  width: 95vw;
  max-width: 650px;
  padding: 0 10px;
  vertical-align: middle;
}

.speech-access-page .search-bar textarea {
  padding: 5px 10px;
  width: 100%;
  height: 100px;
}

.speech-access-page button {
  margin: 5px 0;
}

.speech-access-page .custom-select {
  margin-top: 5px;
}
