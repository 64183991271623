.edu-page {
    height: 100%;
}

.edu-page .logo-header {
    text-align: center;
}

#edu-logo {
    width: 90vw;
    max-width: 500px;
    margin: 40px 0;
}

.card-item {
    display: inline-block;
    margin: 0 3px;
    width: 18rem;
}

.edu-page .btn-link {
    color: black;
    font-weight: 500;
}

.edu-page .card-header {
    background: white;
}

.edu-page .card-header:hover {
    background: rgba(0,0,0,.03);
}

.edu-page .card-item {
    margin-bottom: 5px;
}

.edu-page .accordion > .card {
    border-radius: 0;
    border-right: none;
    border-left: none;
}
