@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.about .container {
  font-family: 'Alegreya', serif;
}

.about .heading {
  text-align: center;
  font-size: 24px;
  padding: 40px 10vw 20px 10vw;
  background: linear-gradient(45deg, #fae0a5, #fbbdab, #ffbffb);
  background-size: 400% 400%;
  animation: gradient 8s linear infinite;
}

.about .presentation .col-md-7 {
  font-size: 20px;
  text-align: center;
  margin: auto;
}

.about .presentation .col-md-5 {
  text-align: center;
}

.about .presentation img {
  max-height: 265px;
  width: auto;
  max-width: 105%;
}

.about .info-contact, .about .news-caption {
  background: rgb(240, 240, 240);
  text-align: center;
  padding: 10px;
}

.about .news-caption {
  margin-bottom: 15px;
}

.creator {
  background: linear-gradient(45deg, #08a0a0, #71bf83);
  padding: 40px 0;
  font-size: 18px;
  color: white;
}

.creator a {
  color: #fbebce;
  text-decoration: underline;
  font-weight: bold;
}

.creator img {
  max-width: 190px;
  border-radius: 100%;
}

.creator .col-md-3 {
  text-align: center;
  padding-bottom: 7px;
}

.head-actions {
  margin-top: 20px;
}

.head-actions .btn {
  margin-top: 5px;
  margin-right: 5px;
}

.fab {
  padding: 10px 0;
  font-size: 12px;
}

.fab-logo-col {
  display: flex;
  align-items: center;
}

.fab-logo-col img {
  width: 100%;
  max-width: 50px;
  margin: 5px auto;
}

@media only screen and (max-width: 770px) {
  .fab-logo-col img {
    width: 100%;
    max-width: 40px;
  }

  .fab {
    padding: 5px;
    font-size: 8px;
  }
}
