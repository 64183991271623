.product-page {
    margin-top: 4vh;
}

.product-page .option-col {
    margin-top: 10px;
}

.product-page .option-col a, .product-page .option-col a:hover {
    color: initial;
    text-decoration: none;
}

.product-option {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    
    height: 100%;
    padding: 15px 7.5px 5px 7.5px;

    border-radius: 4px;
    box-shadow: 0 0 3px grey;
    font-family: 'Alegreya', serif;
}

.product-option:hover {
    box-shadow: 0 0 5px grey;
    position: relative;
    bottom: 1px;
    cursor: pointer;
}

/* .product-option.disabled {
    color: grey;
}

.product-option.disabled:hover {
    color: grey;
    cursor: not-allowed;
    box-shadow: 0 0 3px grey;
} */

.product-option.disabled .product-option-description {
    color: #888;
}

.product-option-description {
    color: #444444;
    font-size: 14px;
}

#email-subscribe-box {
    width: 100%;
}