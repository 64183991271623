.generation-page {
    text-align: center;
}

.generation-page .search-bar {
    margin: 5vh auto;
    padding-top: 10px;
    width: 95vw;
    max-width: 650px;
    padding: 0 10px;
    vertical-align: middle;
}

.generation-page .search-bar textarea {
    padding: 5px 10px;
    width: 100%;
    height: 100px;
}

.generation-page .word {
    display: inline-block;
    font-size: 22px;
    margin: 3px;
    cursor: pointer;
    padding: 0 10px;
}

.generation-page .inputted-sent {
    font-size: 24px;
    margin-bottom: 10px;
}