.audio-read { 
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.audio-read .reading-card {
    max-width: 550px;
    width: 95vw;
    margin: 2.5vh auto;
    height: 80vh;
    border-radius: 25px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    box-shadow: 0px 0px 12px #a6a6a6;
    background: white;
    cursor: pointer;
}

.audio-read .to-be-read {
    font-size: 36px;
    display: inline-block;
    padding: 3px 1px;
    margin: 0 0px;
}

.reading-card img {
    border-radius: 15px;
    width: 90%;
    margin-bottom: 10px;
}

.audio-read #begin-button, #next-button, #exit-button {
    height: 50px;
    padding: 0 10px;
    border-radius: 7px;
    background: white;
    box-shadow: 0px 0px 12px #a6a6a6;
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

.audio-read #next-button {
    position: fixed;
    top: 45vh;
    right: 30px;
}

.audio-read #begin-button {
    display: inline-flex;
    width: 47%;
    float: right;
    height: 50px;
}

.audio-read #exit-button {
    position: fixed;
    top: 45vh;
    left: 30px;
}

.audio-read select {
    padding: 10px;
    border-radius: 7px;
    box-shadow: 0px 0px 12px #a6a6a6;
    width: 47%;
    background: rgba(0, 0, 0, 0);
    border: none;
    height: 50px;
}

.begin-actions {
    position: fixed;
    top: 45vh;
    left: 50vw;
    width: 95vw;
    margin-left: calc(-95vw / 2);
}

.audio-read select option {
    color: black;
}
