@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.api-page .heading {
    text-align: center;
    font-size: 24px;
    padding: 40px 10vw 20px 10vw;
    background: linear-gradient(45deg, #c8abff, #fbbdab);
    margin-bottom: 15px;
    background-size: 400% 400%;
    animation: gradient 5s linear infinite;
}

.api-page .option-col {
    margin-top: 10px;
}

.api-page .option-col a, .api-page .option-col a:hover {
    color: initial;
    text-decoration: none;
}

.usecase-option {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    
    height: 100%;
    padding: 15px 7.5px 5px 7.5px;

    border-radius: 4px;
    box-shadow: 0 0 3px grey;
    font-family: 'Alegreya', serif;
}

.usecase-option:hover {
    box-shadow: 0 0 5px grey;
    position: relative;
    bottom: 1px;
    cursor: pointer;
}

.usecase-option.disabled {
    color: grey;
}

.usecase-option.disabled:hover {
    color: grey;
    cursor: not-allowed;
    box-shadow: 0 0 3px grey;
}

.usecase-option.disabled .usecase-option-description {
    color: #888;
}

.usecase-option-description {
    color: #444444;
    font-size: 18px;
}