.grammar-page {
    text-align: center;
}

.grammar-page .search-bar {
    margin: 20px 0;
    padding-top: 10px;
}

.grammar-page #search-box-input {
    width: 70vw;
    max-width: 650px;
    height: 45px;
    margin: 5px;
    padding: 0 10px;
    vertical-align: middle;
}

.grammar-page button {
    height: 45px;
    vertical-align: middle;
}

.grammar-page .word {
    display: inline-block;
    font-size: 22px;
    margin: 3px;
    cursor: pointer;
    padding: 0 10px;
}

.result-sent {
    max-width: 1000px;
    display: block;
    margin: auto;
}

.ttip {
    background-color: white;
    box-shadow: 0 0 5px rgb(168, 168, 168);
    border-radius: 4px;

    display: block;
    position: absolute;
    left: 0;
    right: 0;

    max-width: 550px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    
    text-align: center;
    font-size: 18px;
}

.legend {
    display: block;
    max-width: 330px;
    position: fixed;
    bottom: 40px;
    left: 5px;
    padding: 10px;
    
    box-shadow: 0 0 5px rgb(168, 168, 168);
    border-radius: 4px;
    
    text-align: left;
    transition: 1s;
    background: white;
    cursor: pointer;
}

.l-color {
    width: 15px;
    height: 15px;
}

.l-color, .l-label {
    display: inline-block;
    vertical-align: middle;
    margin: 0 4px;
    font-size: 14px;
}

.legend .close-icon {
    position: absolute;
    right: 15px;
    font-size: 12px;
}

.comma {
    vertical-align: bottom;
    background: #de7a7a;
    padding: 5px;
    padding-top: 0;
    font-size: 21px;
    font-weight: bold;
    cursor: pointer;
}

#expand-prefs {
    margin: 5px;
    font-size: 24px;
}

.prefs {
    box-shadow: 0 0 5px rgb(168, 168, 168);
    border-radius: 4px;

    padding: 20px 25px 15px 25px;
    width: fit-content;
    text-align: left;
    margin: auto;
    margin-bottom: 15px;
}

.prefs label {
    cursor: pointer;
}

.prefs label input {
    margin-right: 5px;
}

.grammar-page .badge {
    margin-left: 7px;
}

.separator {
    border: 1px solid;
}

.sp1 {
    font-size: 22px;
}

.sp2 {
    font-size: 20px;
    font-weight: bold;
}

.sp3 {
    font-size: 18px;
}

.sp4 {
    font-style: italic;
}

.result-polaritylex p {
    margin-top: 5px;
}