#main-icon {
  width: 165px;
  height: inherit;
}

.nav-item {
  margin: 0px 5px;
  position: relative;
  top: 1px;
  font-weight: 500;
}

.router-link {
  color: dimgray;
}

a.router-link:hover {
  color: black;
  text-decoration: none;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #1a1a2e;
  color: white;
  text-align: center;
  padding: 5px 0;
  font-size: 15px;
}

@media only screen and (max-width: 500px) {
  .footer {
    font-size: 10px;
  }
}