.speech-page {
  text-align: center;
  padding: 3vh 10px;
}

.speech-page .demo-table {
  padding: 10px;
  max-width: 950px;
  margin: auto;
}

.speech-page .demo-caption {
  padding: 1vh 4vw;
}

.speech-page audio {
  width: 300px;
}

@media only screen and (max-width: 750px) {
  .speech-page audio {
    width: 200px;
  }
}

@media only screen and (max-width: 500px) {
  .speech-page audio {
    width: 150px;
  }
}

.speech-paragraph { 
  max-width: 900px;
  margin: auto;
  text-align: justify;
  text-justify: auto;
}

.speech-descr {
  margin: 10px auto;
  max-width: 800px;
}

.speech-page iframe {
  margin: 25px 0px 5px 0px;
}