.api-dashboard {
    text-align: center;
}

.api-dashboard h1 {
    font-size: 2rem;
    margin-top: 10px;
}

.api-dashboard .keys p {
    font-style: italic;
    color: gray;
}

.api-dashboard button {
    margin: 4px;
}

.api-dashboard .keys {
    height: 100%;
    padding: 10px;
    display: flex;
    flex-flow: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    page-break-after: always;
    break-after: always;
}

.api-dashboard .keys .card {
    margin: 5px;
    width: 400px;
}

.api-dashboard .keys .card-key {
    background: gray;
}

.api-dashboard .keys .card-key:hover {
    color: black;
    cursor: pointer;
    background: rgb(210,210,210);
    padding: 2px 5px;
}

.api-dashboard .form-control {
    display: inline-block;
    width: fit-content;
    vertical-align: middle;
    margin: 4px;
}
