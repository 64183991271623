.contacts-page .contacts li {
    font-size: 18px;
    margin: 5px 0;
}

.contacts-page .col {
    margin: auto;
}

.contacts-page {
    margin-top: 15vh;
}

.contacts-page #placeholder-image {
    width: 100%;
}