@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.donate-page {
    font-family: 'Alegreya', serif;
}

.donate-box {
    text-align: center;
    padding: 45px 0;
    background: linear-gradient(45deg, #c8abff, #fbbdab);
    background-size: 400% 400%;
    animation: gradient 5s linear infinite;
}

.donate-page h1 {
    font-size: 32px;
}

#paypal-donate-image {
    margin-top: 30px;
    width: 275px;
    cursor: pointer;
}